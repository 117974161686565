<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
     style="background-color: white !important; color: black !important;"
  >
    <CSidebarBrand class="d-md-down-none" to="/" style="background-color: #F5F5F5 !important;">
      <div style="margin: auto;"><img src="logo_texto.png" style="height: 55px; margin: 15px;"/></div>
    </CSidebarBrand>
    <CRenderFunction flat :contentToRender="menu"/>
  </CSidebar>
</template>

<script>
import ws from '../services/general';
export default {
  name: 'TheSidebar',
  data: function () {
		return {
        menu: []
    }
  },
  mounted: async function() {
      let menu = [];

      let response = await ws.getMenu("sitebar"); 

      let account = JSON.parse(localStorage.getItem("account"));

      if(response.type == "success"){
        menu = response.data;

        response.data.forEach(item => {
            if(item.is_grouper){
                menu.push({_name: 'CSidebarNavTitle', _children: [item.label]});
            }
            else{
              if(item.target == "/new-tickets" && !account.has_validations){
                return;
              }

              menu.push({_name: 'CSidebarNavItem',name: item.label, to: item.target, icon: item.icon});
            }
        });
      }

      this.menu = [{
        _name: 'CSidebarNav',
        _attrs: { style: 'color: #000;' },
        _children: menu
      }];
  },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>

