<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <span style="height: 20px;">{{user_name}} - {{name}} &nbsp;&nbsp;&nbsp;<CIcon :height="20" name="cil-cog" /></span>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Configuración</strong>
    </CDropdownHeader>
    <span v-for="(item, index) in menu">
      <CDropdownItem v-if="!item.is_divider" v-show="item.permission == '' || permissions[item.permission]" :href="item.target">
        <CIcon :name="item.icon" /> {{item.label}}
      </CDropdownItem>
      <CDropdownDivider v-if="item.is_divider" v-show="item.permission == '' || permissions[item.permission]" />
    </span>
    <CDropdownDivider/>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Cerrar Sesión
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import router from '../router/index'
import store from '../store'
import account_ws from '../services/account';
import ws from '../services/general';

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      name: '',
      user_name: '',
      menu: [],
      permissions: null
    }
  },
  mounted: async function() {    
    let account = JSON.parse(localStorage.getItem("account"));
    let user = JSON.parse(localStorage.getItem("user"));
    this.permissions = JSON.parse(localStorage.getItem("permissions"));

    this.name = account.name;
    this.user_name = user.first_name+(user.last_name ? " "+user.last_name : "");

    let response2 = await ws.getMenu("header"); 

    if(response2.type == "success"){
      this.menu = response2.data;
    }
  },
  methods: {
    logout: function(){
      localStorage.removeItem("token");
      store.commit('clear');
      router.push("login");
    },
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>